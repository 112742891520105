import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';

import { AuthProvider } from './context/auth';

import Routes from './routes';
//import { SignIn, Signed } from './routes';

export default function App() {
  const signed = useContext(AuthProvider);

  return (
    <AuthProvider>
      {/* {signed ? <Signed /> : <SignIn />} */}
      <Routes />
      <ToastContainer autoClose={3000} />
    </AuthProvider>
  );
}
