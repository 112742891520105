import React, { useState } from 'react';
import { createContext } from 'react';

import api from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [signed, setSigned] = useState(false);
  const [response, setResponse] = useState({});

  async function login(email, password) {
    try {
      const responseLogin = await api.post('/login', {
        email,
        password,
      });

      setSigned(true);
      setResponse(responseLogin.data);
    } catch (err) {
      alert(err.response.data.msg);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed: signed,
        token: response.token,
        user: response.user,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
