import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './pages/Login';
import Home from './pages/Home';

// const SignIn = () => {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route path="/login" component={Login} />
//       </Switch>
//     </BrowserRouter>
//   );
// };

// const Signed = () => {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route path="/home" exact component={Home} />
//       </Switch>
//     </BrowserRouter>
//   );
// };

// export { SignIn, Signed };

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/home" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}
