import axios from 'axios';

const api = axios.create({
  baseURL: 'https://quizfacil.herokuapp.com',
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@quizfacil:token');

  if (token) {
    config.headers['x-access-token'] = `${token}`;
  }

  return config;
});

export default api;
