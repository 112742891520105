import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Card,
  Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

//import AuthContext from '../../context/auth';

import api from '../../services/api';

export default function Home() {
  //const { signed } = useContext(AuthContext);
  const token = localStorage.getItem('@quizfacil:token');

  const [question, setQuestion] = useState('');
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, [token]);

  function handleLogout() {
    localStorage.removeItem('@quizfacil:token');
    return history.push('/');
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (question.length < 5) {
      return toast.error('A pergunta não pode ter menos que 5 caracteres.');
    }

    if (answer1.length < 1) {
      return toast.error('A pergunta não pode ter menos que 1 caracter.');
    }

    if (answer2.length < 1) {
      return toast.error('A pergunta não pode ter menos que 1 caracter.');
    }

    if (answer3.length < 1) {
      return toast.error('A pergunta não pode ter menos que 1 caracter.');
    }

    if (answer4.length < 1) {
      return toast.error('A pergunta não pode ter menos que 1 caracter.');
    }

    setLoading(true);

    //await login(email, password);

    try {
      const response = await api.post('/questions', {
        name: question,
      });

      const { id } = response.data;

      const response_answers = await api.post(`/answers/${id}`, {
        answers: [
          { name: answer1, correct: true },
          { name: answer2, correct: false },
          { name: answer3, correct: false },
          { name: answer4, correct: false },
        ],
      });

      const { msg } = response_answers.data;
      toast.success(msg);

      setQuestion('');
      setAnswer1('');
      setAnswer2('');
      setAnswer3('');
      setAnswer4('');
    } catch (err) {
      toast.error(err.response.data.msg);
    }

    setLoading(false);
  }

  return (
    <Container>
      <Row>
        <Col />
        <Col xs={6}>
          <Card>
            <Card.Header as="h5">
              <Row>
                <Col>Cadastro de Perguntas</Col>
                <Col>
                  <Button onClick={handleLogout}>Logout</Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Pergunta:</Form.Label>
                  <Form.Control
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Resposta 1: (Resposta verdadeira)</Form.Label>
                  <Form.Control
                    type="text"
                    value={answer1}
                    onChange={(e) => setAnswer1(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Resposta 2:</Form.Label>
                  <Form.Control
                    type="text"
                    value={answer2}
                    onChange={(e) => setAnswer2(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Resposta 3:</Form.Label>
                  <Form.Control
                    type="text"
                    value={answer3}
                    onChange={(e) => setAnswer3(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Resposta 4:</Form.Label>
                  <Form.Control
                    type="text"
                    value={answer4}
                    onChange={(e) => setAnswer4(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  size="lg"
                  block
                  type="submit"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    <span>Cadastrar</span>
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col />
      </Row>
    </Container>
  );
}
