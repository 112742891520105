import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  Button,
  Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import './styles.css';

//import AuthContext from '../../context/auth';

import api from '../../services/api';

export default function Login() {
  //const { signed, login } = useContext(AuthContext);
  const token = localStorage.getItem('@quizfacil:token');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (token) {
      history.push('/home');
    }
  }, [token]);

  async function handleLogin(e) {
    e.preventDefault();

    setLoading(true);

    //await login(email, password);

    try {
      const response = await api.post('/login', {
        email,
        password,
      });

      const { auth, token } = response.data;

      if (auth === true) {
        toast.success('Login efetuado com sucesso.');
        localStorage.setItem('@quizfacil:token', token);
        history.push('/home');
      }
    } catch (err) {
      toast.error(err.response.data.msg);
    }

    setLoading(false);
  }

  return (
    <Container>
      <Row center>
        <Col />
        <Col xs={6}>
          <Card>
            <Card.Header as="h5">Login</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email: </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Digite seu email"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Senha: </Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Digite sua senha"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  block
                  onClick={(e) => handleLogin(e)}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    <span>Login</span>
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col />
      </Row>
    </Container>
  );
}
